import { useGtm } from '@gtm-support/vue-gtm';

interface PromotionData {
    /* eslint-disable @typescript-eslint/naming-convention */
    creative_name: string
    creative_slot: string
    promotion_id: string
    promotion_name: string
    source_url?: string
    target_url?: string
    /* eslint-enable @typescript-eslint/naming-convention */
}

export function usePromotion() {
    const gtm = useGtm();

    const addPromotionClick = (promo: PromotionData): void => {
        gtm?.trackEvent({
            /* eslint-disable @typescript-eslint/naming-convention */
            event: 'view_promotion',
            _clear: true,
            ecommerce: {
                ...promo,
            },
            /* eslint-enable @typescript-eslint/naming-convention */
        });
    };

    return {
        addPromotionClick,
    };
}
